import React from "react";
import {
  PageLayout,
  HeroElement,
  BlogPostNavigation,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class BlogPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { allFishermanBusinessBlogPost } = data;

    return (
      <WebsiteLayout>
        <PageLayout
          hero={
            <HeroElement
              header={"Blog"}
              tagline={""}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid stackable={true} className={"blog-page-container"}>
            <Grid.Column width={16}>
              <BlogPostNavigation
                title={""}
                description={""}
                featuredPostId={null}
                alwaysShowSearch={true}
                columns={4}
                posts={allFishermanBusinessBlogPost.nodes}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    allFishermanBusinessBlogPost {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
  }
`;
